import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/uncategorized/*" />
    <RAC path="/zh-cn/uncategorized/*" />
    <RAC path="/zh-tw/uncategorized/*" />
    <RAC path="/pl/uncategorized/*" />
    <RAC path="/ru/uncategorized/*" />
    <RAC path="/it/uncategorized/*" />
    <RAC path="/de/uncategorized/*" />
    <RAC path="/es/uncategorized/*" />
    <RAC path="/fr/uncategorized/*" />
    <RAC path="/ar/uncategorized/*" />
    <RAC path="/id/uncategorized/*" />
    <RAC path="/pt/uncategorized/*" />
    <RAC path="/ko/uncategorized/*" />
    <DynamicNotFoundPage default />
  </Router>
)
